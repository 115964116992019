import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import barba from '@barba/core';

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

gsap.registerPlugin(ScrollTrigger);

// import './js/script.js';
import './main.scss';
import './fonts.css'

var imagesLoaded = require('imagesloaded');

var lastIndexColor = ''; // rgb value
var nextIndexColor = ''; // integer value (0, 1, 2, 3, etc..)


// fix for mobile height... 😑
window.onload = window.onresize = function () {
  var innerHeight = window.innerHeight;
  document.querySelectorAll(".innerHeight").forEach((ele) => {
    ele.style.height = innerHeight + 'px';
  });
}
 

// ##########################
// ## calls on Page change ##
// ##########################

function getColorTheme(data) {

  lastIndexColor = window.getComputedStyle(document.querySelector("#nav-logo .icon")).backgroundColor;
  if (data.trigger.getAttribute('data-btn') !== 'back' || data.trigger.getAttribute('data-btn') !== 'more' ) { 
    nextIndexColor = data.trigger.getAttribute('data-btn');
  } else {
    nextIndexColor = lastIndexColor;
  }
}

function applyColorTheme() {
    document.querySelector("body").setAttribute('data-currentIndex', nextIndexColor);
};

function applyColorThemeLoadingscreen() {
    document.querySelector("body > div.loading-container > div").style.backgroundColor = lastIndexColor;
}

function processBarbaData(data) {
  // Applies all EventListeners and initiates other Scripts after a barba transition
  let next = data.next.container.getAttribute('data-barba-namespace');
  window.onload()
  applyEventL();
  ScrollTrigger.clearScrollMemory()
  if (next === 'home') {
    initScrollTrigger()
    doScrollIntoView(data)
  } else if (next === '1') {
    initThree()
    initMenuBtn();
  } else if (next === '2') {
    initBtn()
    initDragScroll()
  } else { window.scrollTo(0, 0) }
}

function checkIfPageIsRender(data) {
  let type = document.querySelector('[data-pagetype]').getAttribute('data-pagetype')
  if (type !== 'render') { return }
  else {
    // console.log('current is render');
    // alert('before')
    document.querySelector('CANVAS').remove()
    // alert('after')
    document.querySelector('.loading-container').classList.remove('loaded')
   }
}

function killScrollTriggers() {
  let triggers = ScrollTrigger.getAll();
  triggers.forEach( trigger => {			
  trigger.kill();
  });
}

function doScrollIntoView(data) {

  let dataBtn = data.trigger.getAttribute('data-btn');
  let thisNamespace = dataBtn
  
  if (dataBtn === 'back') { 
    thisNamespace = data.current.container.getAttribute('data-barba-namespace');
  }
  data.next.container.querySelector('#' + CSS.escape(thisNamespace)).scrollIntoView();
  window.scrollBy(0, 10) // prevent black screen from not having 'active' class set by gsap
}

function initScripts() {
  applyEventL(); 
  let thisPage = document.querySelector('[data-pagetype]').getAttribute('data-pagetype');
  if ( thisPage === 'home' ) { initScrollTrigger(); greet();};
  if ( thisPage === 'render' ) { initThree(); initMenuBtn(); }; 
  if ( thisPage === 'webdes' ) { initBtn(); initDragScroll(); }; 
}



// function videoAutoPlay() {   // Safari 'feature' calls video in extra window ...

//     var vids = document.querySelectorAll("VIDEO");
//     vids.forEach(vid => { 
//       var playPromise = vid.play();
//       if (playPromise !== undefined) { 
//         playPromise.then(_ => {}).catch(error => {});
//       }; 
//     });
// }


function initDragScroll() {

  if (!document.querySelector('.scroll-container')) { console.log('returned'); return };
  
  document.querySelectorAll('.scroll-container').forEach((ele => {
    
    let pos = { top: 0, left: 0, x: 0, y: 0 };

    const mouseDownHandler = function (e) {
      ele.style.cursor = 'grabbing';
      ele.style.userSelect = 'none';
      pos = {
          // The current scroll
          left: ele.scrollLeft,
          top: ele.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY,
      };
  
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };
  
    const mouseMoveHandler = function (e) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;
  
      // Scroll the element
      ele.scrollTop = pos.top - dy;
      ele.scrollLeft = pos.left - dx;
    };
  
    const mouseUpHandler = function () {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
  
      ele.style.cursor = 'grab';
      ele.style.removeProperty('user-select');
    };

    ele.addEventListener('mousedown', mouseDownHandler);
  }))
}

function initBtn() { // Button for mobile/desktop switching on the Webdev Page
  document.getElementById('toggle-btn').addEventListener('click', toggleButton);
  document.querySelector('.mask-container').classList.add('transition'); //to prevent animation on load
}
function toggleButton() { // Button for mobile/desktop switching on the Webdev Page
  document.querySelector('.mask-container').classList.toggle('toggled');
}

function applyEventL() { // Checks all '.onClick-btn'-Classes for different reasons => String inside 'data-btn'
  document.querySelectorAll('.onClick-btn').forEach(btn => btn.addEventListener('click', function () { checkButton(this) } ));
}

function initMenuBtn() { // Toggle Overlay on 3D Render Page
  document.querySelector('#toggle-menu').addEventListener('click', toggleMenu);
}
function toggleMenu() { // Toggle Overlay on 3D Render Page
  document.querySelector('#renderpage-menu').classList.toggle('toggled');
}

function toggleMobileMenu() {
  let currentColor = document.body.getAttribute('data-currentindex');
  let navMenu = document.getElementById('nav');
  navMenu.setAttribute('data-index', currentColor);
  navMenu.classList.toggle('toggled');
}

function checkButton(ele) {
  let str = ele.getAttribute('data-btn')

  if (str === 'greet') { greet() } // First <article> greeting in bottom left corner
  if (str === '0') { goTo(0); greet()} // Logo in top left corner
  if (str === 'last') { goTo('last') } // link to Impressum. Actually not necessary..
  if (str === 'burgermenu') { toggleMobileMenu() } // link to Impressum. Actually not necessary..
}

async function goTo(arg) {
  document.getElementById(arg).scrollIntoView();
}

function greet() {
  var array = [ 'Hello!', 'Bonjour!', 'Wassup?', 'Greetings!', 'Hi!', 'Hello there!', 'Oi!', 'Hey!', 'Yeah!' ];
  let max = array.length;
  let rng = Math.floor(Math.random() * (max - 1));
  if (array[rng] == document.getElementById("greet").getAttribute('data-greeting')) { rng++ }
  if (rng > max ) { rng = 0 }

  document.getElementById("greet").setAttribute('data-greeting', array[rng]);
}




// ######################
// ## Barba Animations ##
// ######################

const pageTransition_Out = () => {
  return gsap.to('.loading-screen', { duration: .4, scaleY: 1, transformOrigin: "bottom left", })
}
const pageTransition_In = () => {
  return gsap.to('.loading-screen', { duration: .4, scaleY: 0, transformOrigin: "top left", delay: .0 })
}


// ##############################
// ## barba.jsinitScripts init ##
// ##############################

barba.init({
  
  transitions: [
    {
    // beforeOnce(data) {
    //   console.log('beforeOnce');
    //   console.log('beforeOnce data: ' + data);
    // },

    once(data) {
      // console.log('once');
      // console.log('once data: ' + data);
      
      initScripts() 
    },

    // afterOnce(data) {
    //   console.log('afterOnce');
    //   console.log('afterOnce data: ' + data);
    // },

    // before(data) {
    //   console.log('before');
    //   console.log('before data: ' + data);
    // },
    
    beforeLeave(data) {
      // console.log('beforeLeave');
      getColorTheme(data);
      applyColorThemeLoadingscreen();
      // console.log('beforeLeave END');
    },
    
    leave ({}) {
      // console.log('leave'); 
      checkIfPageIsRender()
      
      return pageTransition_Out();
      
    },
    
    afterLeave(data) {
      // console.log('afterLeave');
      
    },
    
    beforeEnter(data) {
      // alert('beforeEnter')
      // console.log('beforeEnter');
      // alert('Applying Color to next Page' + lastIndexColor);
      killScrollTriggers();
      processBarbaData(data); // initiate eventlisteners
      applyColorTheme();
      applyColorThemeLoadingscreen();
      // videoAutoPlay(); // Safari 'feature' calls video in extra window ...

      // alert('beforeEnter End of Calls')
    },
    
    enter({data}) {  // data = undefined
      // console.log('enter');
      // setTimeout(function() { pageTransition_In(); console.log('Timer ended'); }, 5000)
      imagesLoaded( '.image', function() {
        // if (document.querySelector('[data-pagetype]').getAttribute('data-pagetype') !== 'render') {
          pageTransition_In();
        // } 
      });
    },

    // afterEnter(data) {
    //   console.log('afterEnter');
    //   console.log('afterEnter data: ' + data);
    // },

    after(data) {
      // console.log('after');
      ScrollTrigger.refresh()
    },
  },
  ]
});



// # ############## # //
// # gsap scrolling # //
// # ############## # //

var initScrollTrigger = function() {
 
  let progbar = gsap.fromTo(".progressbar", { width: 0 }, { width: '100%'});
  let article = document.querySelectorAll('[data-scroll="1"]');

  article.forEach((element) => {
    ScrollTrigger.create({
      trigger: element,
      scrub: true,
      animation: progbar,
      onEnter: () => {
        let arg = element.getAttribute('data-index');
        document.body.setAttribute('data-currentindex', arg);
        element.classList.add('active');
        // console.log(arg);
        
      },
      onEnterBack: () => {
        let arg = element.getAttribute('data-index');
        document.body.setAttribute('data-currentindex', arg);
        element.classList.add('active');
        // console.log(arg);

      },
      onLeave: () => {
        element.classList.remove('active')
      },
      onLeaveBack: () => {
        element.classList.remove('active')
        // console.log(element);
      },
      start: "bottom-=2 bottom",
      end: "bottom-=2 top",
      
      // start: "bottom-=2 bottom",
      // end: "bottom-=2 50%",
      // markers: true
    });
    })
  // })

};








//####################### three.js ########################
//####################### three.js ########################
//####################### three.js ########################
//####################### three.js ########################

let renderer, scene, camera, controls;

function initThree () {
  init().catch( function ( err ) {
    console.error( err );
  });


function doDispose() {
  // alert('dispose commencing');
  Object.keys(renderer).forEach((key) => {
    // console.log(key);
    if (typeof [key].dispose === 'function') {
      [key].dispose();
    }
  });
  renderer.dispose()
}

function doToggleCustomMenu() {
  document.getElementById('overlay').classList.toggle('toggle');
}

async function init() {
  renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true } );
  renderer.setClearColor(0xffffff, 0);
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.setAnimationLoop( render );
  document.body.querySelector('#render').appendChild( renderer.domElement );

  const loadingManager = new THREE.LoadingManager( () => {
	

    document.querySelectorAll('[data-dispose]').forEach(btn => btn.addEventListener('click', function () { doDispose() } ));
    document.querySelectorAll('[data-menu]').forEach(btn => btn.addEventListener('click', function () { doToggleCustomMenu() } ));
		
    const loadingScreen = document.querySelector( '.loading-container' );
		loadingScreen.classList.add( 'loaded' );
	} );




  scene = new THREE.Scene();

  camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.25, 20 );
  camera.position.set( 4.5, 2, 4.5 );

  scene.add(camera)

  controls = new OrbitControls( camera, renderer.domElement );
  controls.addEventListener( 'change', render );
  controls.target.set( 0, 0.2, 0 );
  controls.update();

  
  const rgbeLoader = new RGBELoader()
    // .setPath( 'textures/equirectangular/' );

  const gltfLoader = new GLTFLoader( loadingManager )
    // .setPath( 'models/gltf/' );

  const textureLoader = new THREE.TextureLoader(  )

  const [ hdri, gltf, diffuseDeck, normalDeck, diffuseWheels, normalWheels, normalTrucks ] = await Promise.all( [
    rgbeLoader.loadAsync( './assets/3DModule/aristea_wreck_puresky_1k.hdr' ),
    gltfLoader.loadAsync( './assets/3DModule/Skateboard.gltf' ),
    textureLoader.loadAsync( './assets/3DModule/baked_Deck_diffuse00.jpg' ),
    textureLoader.loadAsync( './assets/3DModule/baked_Deck_normal.jpg' ),
    textureLoader.loadAsync( './assets/3DModule/baked_Wheels_diffuse00.jpg' ),
    textureLoader.loadAsync( './assets/3DModule/baked_Wheels_normal.jpg' ),
    textureLoader.loadAsync( './assets/3DModule/baked_Trucks_normal.jpg' ),
  ] );
  // const [ hdri, gltf, diffuseDeck, normalDeck, diffuseWheels, normalWheels, normalTrucks ] = await Promise.all( [
  //   rgbeLoader.loadAsync( '/aristea_wreck_puresky_1k.hdr' ),
  //   gltfLoader.loadAsync( '/Skateboard.gltf' ),
  //   textureLoader.loadAsync( '/baked_Deck_diffuse00.jpg' ),
  //   textureLoader.loadAsync( '/baked_Deck_normal.jpg' ),
  //   textureLoader.loadAsync( '/baked_Wheels_diffuse00.jpg' ),
  //   textureLoader.loadAsync( '/baked_Wheels_normal.jpg' ),
  //   textureLoader.loadAsync( '/baked_Trucks_normal.jpg' ),
  // ] );

  // hdri.mapping = THREE.EquirectangularReflectionMapping;

  diffuseDeck.flipY = false;
  diffuseWheels.flipY = false;
  normalDeck.flipY = false;
  normalWheels.flipY = false;
  normalTrucks.flipY = false;
 
  diffuseDeck.encoding=THREE.sRGBEncoding;
  diffuseDeck.needsUpdate=true;
  diffuseWheels.encoding=THREE.sRGBEncoding;
  diffuseWheels.needsUpdate=true;


  gltf.scene.rotation.x = -0.2
  gltf.scene.rotation.y = 2
  gltf.scene.rotation.z = 4.5


  var wheelColors = {
    beige: { r: 0.89, g: 0.79, b: 0.59 },
    white: { r: 1, g: 1, b: 1 },
    black: { r: 0.02, g: 0.01, b: 0.02 },
  };

  
  function doChangeGLTF(value, param) {
    
    gltf.scene.traverse(function(node) {
      if (node instanceof THREE.Mesh) {
        if (param === 'WheelColor') {
          if (node.material.name === 'Wheel_Color' || node.material.name === 'Wheel_ripples' ) {
            node.material.color = value
            return
          }
        }
        else if (param === 'WheelDiffuse') {
          if (node.material.name === 'Wheel_graphic' ) {
            node.material.map = value
            return
          }
        }
        else if (param === 'DeckDiffuse') {
          if (node.material.name === 'Board_graphic' ) {
            node.material.map = value
            return
          }
        }
      }
    })
  };

  document.getElementById('uiWheelColor').querySelectorAll('INPUT').forEach(btn => btn.addEventListener('click', function () { checkWColor(this) } ));
  document.getElementById('uiWheelDiffuse').querySelectorAll('INPUT').forEach(btn => btn.addEventListener('click', function () { checkDiffuse(this) } ));
  document.getElementById('uiDeckDiffuse').querySelectorAll('INPUT').forEach(btn => btn.addEventListener('click', function () { checkDiffuse(this) } ));
  
  function checkWColor(node) {
    let value = wheelColors[Object.keys(wheelColors)[node.value]];
    let param = 'WheelColor'
    doChangeGLTF(value, param)
  };

  function checkDiffuse(node) {
    let tempSTR;
    if (node.name === 'WheelDiffuse') { 
      tempSTR = './assets/3DModule/baked_Wheels_diffuse0' + node.value + '.jpg';
      // tempSTR = '/baked_Wheels_diffuse0' + node.value + '.jpg';
    }
    else if (node.name === 'DeckDiffuse') {
      tempSTR = './assets/3DModule/baked_Deck_diffuse0' + node.value + '.jpg';
      // tempSTR = '/baked_Deck_diffuse0' + node.value + '.jpg';
    }
    console.log(tempSTR);
    console.log(node);
    let value = textureLoader.load( tempSTR );
    value.flipY = false;
    value.encoding = THREE.sRGBEncoding;
    doChangeGLTF(value, node.name)
  }


  // mesh

  // TRAVERSE ALL 
  function doTraverse() {

    gltf.scene.traverse(function(node) {
    if (node instanceof THREE.Mesh) {
        // console.log(node.name);
        switch(node.material.name) {
          case 'Metal_Silver':
            node.material.color.r = .45;
            node.material.color.g = .45;
            node.material.color.b = .45;
            node.material.normalMap = normalTrucks;
            // node.material.normalMap = null;
            // node.material.normalMap = normalDeck;
            node.material.envMapIntensity = 1;
            node.material.metalness = 1;
            node.material.roughness = .4;

            break;
          case 'KingPin':
            node.material.color.r = 0.05;
            node.material.color.g = 0.0;
            node.material.color.b = 0.0;
            node.material.metalness = 0;
            node.material.roughness = .65;
            break;
          case 'Plastic_Bearing':
            node.material.color.r = 0.1;
            node.material.color.g = 0.0;
            node.material.color.b = 0.0;
            node.material.metalness = 0;
            node.material.roughness = .65;
            break;
          case 'Wheel_Color':
            node.material.color = wheelColors.beige
            
            node.material.roughness = .8;
            node.material.envMapIntensity = .4;
            break;
          case 'Wheel_graphic':
            node.material.map = diffuseWheels;
            break;
          case 'Wheel_ripples':
            node.material.color = wheelColors.beige

            node.material.roughness = .8;
            node.material.envMapIntensity = .4;

            node.material.normalMap = normalWheels;
            node.material.normalScale.x = 15;
            node.material.normalMap.repeat.x = 45;
            node.material.normalMap.repeat.y = 0;
            node.material.normalMap.wrapS = THREE.MirroredRepeatWrapping;
            break;
          case 'Wood':
            node.material.map = diffuseDeck;
            node.material.metalness = 0;
            break;
          case 'Griptape':
            node.material.map = diffuseDeck;
            node.material.normalMap = normalDeck;
            node.material.metalness = 0;
            node.material.roughness = .6;
            break;
          case 'Board_graphic':
            node.material.map = diffuseDeck;
            node.material.normalMap = null;
            node.material.roughness = .3;
            node.material.envMapIntensity = 0;
            break;
          default:
            break;
        }
        // console.log(node.material);
      } 
                    
    });
  }
  doTraverse();


  // environment

  hdri.mapping = THREE.EquirectangularReflectionMapping;

  // scene.background = new THREE.clearColor( 0x000000, 0 );
  // scene.background = hdri
  scene.environment = hdri;

  // model

  scene.add( gltf.scene );

  window.addEventListener( 'resize', onWindowResize );

  //gui helpers
  // gui.add(gltf.scene.rotation, 'x').min(0).max(8).step(0.1)
  // gui.add(gltf.scene.rotation, 'y').min(0).max(8).step(0.1)
  // gui.add(gltf.scene.rotation, 'z').min(0).max(8).step(0.1)
  

// #ANIMATE
// const clock = new THREE.Clock()
// const tick = () =>
// {
//     const elapsedTime = clock.getElapsedTime()

//     // Update objects
//     gltf.scene.rotation.y = 2 * elapsedTime
//     gltf.scene.rotation.x = .6 * elapsedTime
   
//     // Update Orbital Controls
//     // controls.update()

//     // Render
//     renderer.render(scene, camera)

//     // Call tick again on the next frame
//     window.requestAnimationFrame(tick)
// }
// tick()




// console.log(scene);
} //END OF INIT()



// Lights
const pointLight = new THREE.PointLight(0xffffff, .4)
pointLight.position.x = 3
pointLight.position.y = 2
pointLight.position.z = 4
camera.add(pointLight)

const rectLight = new THREE.RectAreaLight( 0xffffff, 2,  10, 10 );
rectLight.position.set( 5, 5, 3 );
rectLight.lookAt( 0, 0, 0 );
scene.add( rectLight )

// const light = new THREE.AmbientLight( 0x404040 ); // soft white light
// const light = new THREE.AmbientLight( 0x707070 ); // soft white light
// const light = new THREE.HemisphereLight( 0x404040 ) ; // soft white light
// scene.add( light );








// const rectLightHelper = new RectAreaLightHelper( rectLight );
// rectLight.add( rectLightHelper );


// gui
// const gui = new dat.GUI()
// gui.add(camera.position, 'x').min(-2).max(8).step(0.1)
// gui.add(camera.position, 'y').min(-2).max(2).step(0.1)
// gui.add(camera.position, 'z').min(0).max(8).step(0.1)


function onWindowResize() {

  camera.aspect = window.innerWidth / window.innerHeight;

  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );

}

function render() {

  renderer.render( scene, camera );

}


}
