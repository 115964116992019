// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/fa-solid-900.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Inconsolata-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/FiraSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/FiraSans-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/fonts/FiraSans-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./assets/fonts/FiraSans-SemiBold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"FontAwesomeSolid\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:\"Inconsolata\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@font-face{font-family:\"FiraSans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-weight:normal}@font-face{font-family:\"FiraSans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");font-weight:bold}", "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAIE,WACE,8BAAA,CACA,2CAAA,CAGF,WACE,yBAAA,CACA,2CAAA,CAGF,WACE,sBAAA,CACA,2CAAA,CACA,2CAAA,CACA,kBAAA,CAGF,WACE,sBAAA,CACA,2CAAA,CACA,2CAAA,CACA,gBAAA","sourcesContent":["\r\n/* ############################# */\r\n\r\n\r\n  @font-face {\r\n    font-family: \"FontAwesomeSolid\";\r\n    src: url('./assets/fonts/fa-solid-900.woff2');     \r\n  }\r\n\r\n  @font-face {\r\n    font-family: \"Inconsolata\";\r\n    src: url('./assets/fonts/Inconsolata-Regular.ttf'); \r\n  }\r\n\r\n  @font-face {\r\n    font-family: \"FiraSans\";\r\n    src: url('./assets/fonts/FiraSans-Regular.ttf');     \r\n    src: url('./assets/fonts/FiraSans-Regular.woff');\r\n    font-weight: normal;   \r\n  }\r\n\r\n  @font-face {\r\n    font-family: \"FiraSans\";\r\n    src: url('./assets/fonts/FiraSans-SemiBold.ttf');     \r\n    src: url('./assets/fonts/FiraSans-SemiBold.woff');\r\n    font-weight: bold;   \r\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
